import { AxiosResponse } from 'axios';

import electionsResponse from '../data/midterms/elections.json';
import positionsResponse from '../data/midterms/positions.json';
import measures from '../data/measures.json';
import candidate from '../data/midterms/fullCandidate.json';
import electionData from '../data/midterms/googleCivicElections.json';
import pollingData from '../data/midterms/googleCivicVoterInfo.json';
import type {
  ElectionsResponse,
  PositionsResponse,
  MeasureResponse,
  FullCandidate,
  Election,
} from '../types/ballotReady';
import {
  PollingLocation,
  Election as GoogleCivicElection,
} from '../types/googleCivic';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const MIN_DELAY = 1;
const MAX_DELAY = 50;

const getRandomDelayTimeInMS = (min = MIN_DELAY, max = MAX_DELAY): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const mockDelay = async (): Promise<void> => {
  const delay = getRandomDelayTimeInMS();

  await sleep(delay);
};

const fakeAxiosResponse = (data: any): AxiosResponse => ({
  data,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
});

export const getFakeBallotReadyElections = async (): Promise<
  AxiosResponse<ElectionsResponse>
> => {
  await mockDelay();
  return fakeAxiosResponse(electionsResponse as unknown as ElectionsResponse);
};

export const getFakeBallotReadyPositions = async (): Promise<
  AxiosResponse<PositionsResponse>
> => {
  await mockDelay();

  return fakeAxiosResponse(positionsResponse as unknown as PositionsResponse);
};

export const getFakeBallotReadyMeasures = async (): Promise<
  AxiosResponse<MeasureResponse>
> => {
  await mockDelay();

  return fakeAxiosResponse(measures as MeasureResponse);
};

export const getFakeBallotReadyCandidate = async (): Promise<
  AxiosResponse<FullCandidate>
> => {
  await mockDelay();

  return fakeAxiosResponse(candidate as unknown as FullCandidate);
};

export const getFakeGoogleCivicElections = async (): Promise<
  AxiosResponse<GoogleCivicElection[]>
> => {
  await mockDelay();

  return fakeAxiosResponse(electionData.elections as unknown as Election[]);
};

export const getFakeGoogleCivicVoterInfo = async (): Promise<
  AxiosResponse<PollingLocation[]>
> => {
  await mockDelay();

  return fakeAxiosResponse(
    pollingData.earlyVoteSites as unknown as PollingLocation[],
  );
};
