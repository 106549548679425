import React, { FC, ReactElement } from 'react';
import type { AlertProps } from '@chakra-ui/react';

import type { Election } from '../../types/ballotReady';
import { Alert } from '../ui/Alert';
import { formatDate, scaleValues } from '../../utils/helpers';
import { useTextSize } from '../../contexts';

interface ElectionCardProps extends AlertProps {
  election: Election;
}

export const ElectionCard: FC<ElectionCardProps> = ({
  election,
  ...rest
}): ReactElement => {
  const { textSize } = useTextSize();

  return (
    <Alert
      title={election.name}
      height={scaleValues('110px', '100px', textSize)}
      {...rest}
    >
      {formatDate(election.election_day)}
    </Alert>
  );
};
