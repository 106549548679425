import React, { ReactElement } from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { AssignmentInd } from '@mui/icons-material';

import { useSavedAddress } from '../../contexts';
import { Page } from '../../layout';
import { NavigationLink } from '../../components';

import { electionAuthorityData } from './getElectionAuthority';

interface VotingMethod {
  type: 'in-person' | 'by-mail' | 'early-voting';
  primary: boolean;
  instructions?: { 'voting-id': string };
  'excuse-required': boolean;
}

interface VotingMethods {
  'voting-methods': VotingMethod[];
}

interface OcdDivisionAuthorities {
  0: string;
  1: string;
  2: VotingMethods;
}

export const IDRequirementsPage = (): ReactElement => {
  const { address } = useSavedAddress();
  const votingMethod = 'in-person';

  const cleanElectionAuthorityData =
    electionAuthorityData.items[0].fields.data.authorities;
  const stateData = cleanElectionAuthorityData.find(
    (electionAuthorityData) =>
      address && address.ocdId === electionAuthorityData['ocd-division'][0],
  );
  const ocdAuth = (
    stateData?.['ocd-division'] as unknown as OcdDivisionAuthorities
  )[2];
  const stateVotingMethodInstruction = ocdAuth['voting-methods'].find(
    (v) =>
      v.type === votingMethod && v.instructions && v.instructions['voting-id'],
  );
  return (
    <main>
      <NavigationLink
        href="/home/checklist"
        text="Back to Checklist"
        left={['3', '10']}
        top={['4', '5']}
      />
      <Page
        justifyContent="center"
        title="ID Requirements"
        ariaLabel="id requirements page"
      >
        <HStack mt={8}>
          <Icon color="brand.blue" boxSize="1.5em" as={AssignmentInd} />
          <Text as="h1" textStyle="h1">
            ID Requirements Page
          </Text>
        </HStack>

        <Text style={{ whiteSpace: 'pre-wrap' }}>
          {stateVotingMethodInstruction?.instructions?.['voting-id']}
        </Text>
      </Page>
    </main>
  );
};
