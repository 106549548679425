import React, { FC, ReactElement, useMemo } from 'react';
import {
  Tabs,
  TabList,
  Tab,
  Icon,
  chakra,
  useTheme,
  Box,
  useColorMode,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ListIcon from '@mui/icons-material/List';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Link, useLocation } from 'react-router-dom';

import { fixedCustomStyles } from '../theme/foundations/fixedTextSizes';
import { useIsDesktop } from '../hooks';

interface PageWrapper {
  page: ReactElement;
}

export const HomepageWrapper: FC<PageWrapper> = ({ page }): ReactElement => {
  const { pathname } = useLocation();
  const isDesktop = useIsDesktop();
  const { sizes } = useTheme();

  const modeProps = useColorMode();

  const getTabBorder = () => {
    if (modeProps.colorMode === 'dark') {
      return {
        border: '2px solid',
        borderColor: mode('none', 'cyan')(modeProps),
      };
    }
  };

  const getTabColor = () => {
    if (modeProps.colorMode === 'dark') {
      return 'brand.yellow';
    }
    return 'brand.black';
  };

  const activeIndex = useMemo(() => {
    if (pathname === '/home/checklist') {
      return 0;
    } else if (pathname === '/home/faq') {
      return 1;
    } else if (pathname.includes('/home/ballot')) {
      return 2;
    } else if (pathname.includes('/home/help')) {
      return 3;
    }

    return 0;
  }, [pathname]);

  const [checklistText, faqText, ballotText, helpText] = useMemo(() => {
    if (isDesktop) {
      return ['Voting Checklist', 'Voter FAQ', 'Ballot', 'Help'];
    }

    return ['Checklist', 'FAQ', 'Ballot', 'Help'];
  }, [isDesktop]);

  return (
    // Note: Needed to get rid of nested main tabs, leaving for reference
    // <main aria-label="home page checklist, faqs, and ballot">
    <Box width="100%">
      <Tabs
        position="sticky"
        top={sizes.navHeight}
        height={isDesktop ? sizes.tabHeight : sizes.tabHeightMobile}
        // TODO: Fix error of tabs focus being cut off on top
        // If you make z index of tabs larger than nav bar, it works but the
        // mobile menu will show up underneath the tabs
        zIndex="banner"
        defaultIndex={activeIndex}
        index={activeIndex}
      >
        <chakra.nav
          top={sizes.navHeight}
          position="sticky"
          zIndex="docked"
          aria-label="secondary navigation"
          height="100%"
        >
          <TabList
            backgroundColor={mode('brand.lightBase', 'black')(modeProps)}
            height="100%"
            position="sticky"
          >
            <Tab
              as={Link}
              to={'/home/checklist'}
              display="flex"
              flexDirection={['column', 'row']}
              fontSize={fixedCustomStyles.textStyles.homePageTab.fontSize}
              lineHeight={fixedCustomStyles.textStyles.homePageTab.lineHeight}
              color={getTabColor()}
              minW="fit-content"
              _selected={getTabBorder()}
            >
              <Icon marginRight={['0', '5px']} as={CheckBoxIcon} />
              {checklistText}
            </Tab>
            <Tab
              as={Link}
              to={'/home/faq'}
              display="flex"
              flexDirection={['column', 'row']}
              fontSize={fixedCustomStyles.textStyles.homePageTab.fontSize}
              lineHeight={fixedCustomStyles.textStyles.homePageTab.lineHeight}
              color={getTabColor()}
              _selected={getTabBorder()}
              minW="fit-content"
            >
              <Icon marginRight={['0', '5px']} as={HelpOutlineIcon} />
              {faqText}
            </Tab>
            <Tab
              as={Link}
              to={'/home/ballot'}
              display="flex"
              flexDirection={['column', 'row']}
              fontSize={fixedCustomStyles.textStyles.homePageTab.fontSize}
              lineHeight={fixedCustomStyles.textStyles.homePageTab.lineHeight}
              color={getTabColor()}
              _selected={getTabBorder()}
              minW="fit-content"
            >
              <Icon marginRight={['0', '5px']} as={ListIcon} />
              {ballotText}
            </Tab>
            <Tab
              as={Link}
              to={'/home/help'}
              display="flex"
              flexDirection={['column', 'row']}
              fontSize={fixedCustomStyles.textStyles.homePageTab.fontSize}
              lineHeight={fixedCustomStyles.textStyles.homePageTab.lineHeight}
              color={getTabColor()}
              _selected={getTabBorder()}
              minW="fit-content"
            >
              <Icon marginRight={['0', '5px']} as={MenuBookIcon} />
              {helpText}
            </Tab>
          </TabList>
        </chakra.nav>
      </Tabs>
      {page}
      {/* </main> */}
    </Box>
  );
};
