export const landingPageContent = {
  'en-US': {
    title: 'Voting made easy',
    instructions: 'Enter your address to find your voting checklist and ballot',
    content:
      'Your data is yours! Your address and favorited candidates are not stored on Brink’s servers.',
    placeholder: 'Your address, e.g. 123 Street, City',
  },
  es: {
    title: 'Votar de forma fácil',
    instructions:
      'Ingrese su dirección para encontrar su lista de verificación de votación y boleta',
    content:
      '¡Tus datos son tuyos! Su dirección y sus candidatos favoritos no se almacenan en los servidores de Brink.',
    placeholder: 'Su dirección, por ejemplo, calle 123, cuidad',
  },
};
