import React, { ReactElement, useEffect, useState } from 'react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { Box, Flex, useColorMode, Heading, useTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import ReactMarkdown from 'react-markdown';

import { markdownTheme } from '../../markdown';
import { HelpContent } from '../../types/contentful';
import { Contentful } from '../../api';
import { NavigationLink } from '../../components';
import { useLocale } from '../../contexts';

export const MailInVotingInfo = (): ReactElement => {
  const [mailInfo, setMailInfo] = useState<HelpContent>();
  const { locale } = useLocale();

  useEffect(() => {
    const loadMailData = async (): Promise<void> => {
      const mailInfo = await Contentful.getHelpContent(
        'voting_method',
        locale,
        'by-mail',
      );
      if (mailInfo !== null) {
        setMailInfo(mailInfo[0]);
      }
    };
    loadMailData();
  }, [locale]);

  useEffect(() => {
    document.title = 'MailInVotingInfo | Brink';
  }, []);

  const modeProps = useColorMode();
  const white = mode('white', 'black')(modeProps);
  const { sizes } = useTheme();

  return (
    <main>
      <Flex
        width="100vw"
        background={white}
        top={sizes.navHeight}
        height="8vh"
        position="sticky"
        alignItems="center"
      >
        <NavigationLink
          href="/home/checklist"
          text="Back to Checklist"
          left={['3', '10']}
        />
      </Flex>
      <Box backgroundColor={white} w={['90%', '60%', '50%']} mt={6} mx="auto">
        <Heading as="h1" my={6} textStyle="h1" fontSize={['28px', '32px']}>
          Mail In Your Ballot
        </Heading>
        <ReactMarkdown skipHtml components={ChakraUIRenderer(markdownTheme)}>
          {mailInfo?.body ?? ''}
        </ReactMarkdown>
      </Box>
    </main>
  );
};

export default MailInVotingInfo;
