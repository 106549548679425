import React, {
  createContext,
  FC,
  ReactElement,
  useState,
  useEffect,
  useContext,
} from 'react';

export type SetTextSize = React.Dispatch<React.SetStateAction<number>>;
interface TextSizeInterface {
  textSize: number;
  setTextSize: SetTextSize;
}

const TextSizeContext = createContext<TextSizeInterface | undefined>(undefined);

//@TODO: Put all localstorage keys in a localstorage constants file
export const TEXT_SIZE_KEY = 'brink-scale-text-size';

const extractTextSize = (): number => {
  const storedTextSize = localStorage.getItem(TEXT_SIZE_KEY);

  if (!storedTextSize) {
    return 1;
  }

  const parsedTextSize = parseFloat(storedTextSize);

  if (isNaN(parsedTextSize)) {
    return 1; // this should never happen? ask Ayan, but decide whether we should remove the key from `localStorage` and `return 1`
  }

  return parsedTextSize;
};

const TextSizeContextProvider: FC = ({ children }): ReactElement => {
  const [textSize, setTextSize] = useState(extractTextSize);

  useEffect(() => {
    if (textSize === 0) {
      return;
    }

    localStorage.setItem(TEXT_SIZE_KEY, textSize.toString());
  }, [textSize]);
  return (
    <TextSizeContext.Provider value={{ textSize, setTextSize }}>
      {children}
    </TextSizeContext.Provider>
  );
};

export default TextSizeContextProvider;

export const useTextSize = () => {
  const context = useContext(TextSizeContext);

  if (!context) {
    throw new Error(
      'useTextSize must be used inside the TextSizeContext provider',
    );
  }

  return context;
};
