import React, { ReactElement, useState, useEffect } from 'react';
import {
  VStack,
  Center,
  Text,
  RadioGroup,
  Button,
  Link,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { VotingMethodCard } from '../../components/card/VotingMethodCard';
import { VotingAndRegistrationMethods } from '../../api/contentful';
import { Contentful } from '../../api';
import {
  useBallotData,
  useSavedAddress,
  useVotingMethod,
  useLocale,
} from '../../contexts';
import { BackButton, ElectionCard } from '../../components';
import {
  HelpContent,
  VotingMethodData,
  VotingMethodType,
} from '../../types/contentful';
import { Disclaimer } from '../../components/ui/Disclaimer';

const sortOrder: Record<VotingMethodType, number> = {
  'early-voting': 0,
  'by-mail': 1,
  'in-person': 2,
};

export const VotingMethodSelectionPage = (): ReactElement => {
  const [votingRegistrationData, setVotingRegistrationData] =
    useState<VotingAndRegistrationMethods>();
  const { elections, isLoading } = useBallotData();
  const { address } = useSavedAddress();
  const navigate = useNavigate();
  const { votingMethod, setVotingMethod } = useVotingMethod();
  const [selectedVotingType, setVotingType] = useState<VotingMethodType | null>(
    votingMethod,
  );
  const [helpPageData, setHelpPageData] = useState<HelpContent[]>();
  const { locale } = useLocale();

  const getSortedVotingLevels = (
    levels: VotingMethodData[] | undefined,
  ): VotingMethodData[] => {
    if (!levels) {
      return [];
    }

    return levels.sort((a, b) => sortOrder[a.type] - sortOrder[b.type]);
  };

  useEffect(() => {
    document.title = 'Checklist | Brink';
  }, []);

  useEffect(() => {
    const retrieveData = async (ocdId: string) => {
      const res = await Contentful.getVotingAndRegistrationMethods(
        ocdId,
        locale,
      );

      if (!res.votingMethods.length) {
        const defaultVotingMethod = 'in-person';
        setVotingMethod(defaultVotingMethod);
        navigate('/home/checklist/');
      }
      setVotingRegistrationData(res);
    };

    if (address) {
      retrieveData(address.ocdId);
    }
  }, [address, locale, navigate, setVotingMethod]);

  useEffect(() => {
    const retrieveData = async () =>
      setHelpPageData(await Contentful.getHelpContent('voting_method', locale));
    retrieveData();
  }, [locale]);

  const missingVotingMethod = helpPageData?.find(
    (content) => content.order === 4,
  )?.body;

  const handleClick = () => {
    if (selectedVotingType) {
      setVotingMethod(selectedVotingType);
      navigate('/home/checklist/');
    }
  };

  return (
    <main>
      {votingMethod && <BackButton />}
      <Center width="100%" marginTop="35px">
        <VStack
          width="70%"
          maxWidth="600px"
          minWidth="300px"
          spacing="25px"
          marginBottom="35px"
        >
          {!votingMethod && (
            <VStack width="100%" spacing="10px">
              {!isLoading ? (
                <VStack width="100%" spacing="10px">
                  <Text alignSelf="flex-start" as="h1" textStyle="h1">
                    We have found an upcoming election!
                  </Text>
                  <Text>
                    To build your voting checklist, select your voting method.
                    You can change your method in the “Voting Checklist”
                    section.
                  </Text>
                  {elections.map((election) => (
                    <ElectionCard election={election} key={election.id} />
                  ))}
                </VStack>
              ) : (
                <Center>
                  <Spinner size="md" />
                </Center>
              )}
            </VStack>
          )}
          <VStack width="100%" spacing="12.5px">
            <Text as="h2" textStyle="h2" alignSelf="flex-start">
              {votingMethod
                ? 'Update your voting method'
                : 'Select your voting method'}
            </Text>
            <RadioGroup
              width="100%"
              onChange={(type) => setVotingType(type as VotingMethodType)}
              value={selectedVotingType!}
            >
              <VStack width="100%" spacing="12.5px">
                {getSortedVotingLevels(
                  votingRegistrationData?.votingMethods,
                ).map((votingMethod) => (
                  <VotingMethodCard
                    votingType={votingMethod}
                    key={votingMethod.type}
                  />
                ))}
              </VStack>
            </RadioGroup>

            <Button
              as={Link}
              variant="accent"
              disabled={selectedVotingType === null}
              onClick={handleClick}
              alignSelf="flex-end"
            >
              Confirm voting method
            </Button>
          </VStack>
          <Disclaimer
            heading="Don't see your voting method?"
            description={missingVotingMethod ?? ''}
          />
        </VStack>
      </Center>
    </main>
  );
};
