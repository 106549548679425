import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  useColorMode,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Select,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React, { Fragment, ReactElement } from 'react';

import { fixedCustomStyles } from '../../theme/foundations/fixedTextSizes';
import { useTextSize, useLocale } from '../../contexts';
import {
  LocaleType,
  LocaleLanguageMapping,
} from '../../types/contentful/locale';

export const AccessibilityForm = (): ReactElement => {
  const modeProps = useColorMode();
  const rightMarkerLocation = 1.8;
  const { locale, setLocale } = useLocale();
  const { textSize, setTextSize } = useTextSize();

  const handleLocaleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocale(e.target.value as LocaleType);
  };

  return (
    <Fragment>
      <Box>
        <FormControl>
          <FormLabel fontWeight="bold" htmlFor="language-select" mb={3}>
            Language
          </FormLabel>
          <Select
            value={locale as string}
            onChange={handleLocaleChange}
            borderColor="black"
            id="language-select"
          >
            {Object.keys(LocaleLanguageMapping).map((localeKey) => (
              <option key={localeKey} value={localeKey}>
                {LocaleLanguageMapping[localeKey as LocaleType]}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box alignContent="center">
        <FormLabel
          fontWeight="bold"
          id="text-size-slider"
          mb={3}
          fontSize={fixedCustomStyles.textStyles.body.fontSize}
          lineHeight={fixedCustomStyles.textStyles.body.lineHeight}
        >
          Text Size
        </FormLabel>
      </Box>
      <Box alignContent="center" alignItems="center">
        <Box>
          <Slider
            aria-label="slider-ex-1"
            width="100%"
            colorScheme="brand.blue"
            min={1}
            max={2}
            step={0.1}
            defaultValue={textSize}
            onChangeEnd={setTextSize}
            marginTop="-22px"
            marginBottom="22px"
          >
            <SliderMark
              value={1}
              marginTop="12px"
              marginBottom="12px"
              fontSize={fixedCustomStyles.textStyles.sliderDefault.fontSize}
              lineHeight={fixedCustomStyles.textStyles.sliderDefault.lineHeight}
            >
              Default
            </SliderMark>
            <SliderMark
              value={rightMarkerLocation}
              fontSize={fixedCustomStyles.textStyles.sliderLarge.fontSize}
              lineHeight={fixedCustomStyles.textStyles.sliderLarge.lineHeight}
              marginTop="8px"
              marginBottom="12px"
            >
              Large
            </SliderMark>

            <SliderTrack>
              <SliderFilledTrack bg={mode('brand.blue', 'yellow')(modeProps)} />
            </SliderTrack>
            <SliderThumb bg={mode('brand.blue', 'white')(modeProps)} />
          </Slider>
        </Box>
      </Box>

      <Box>
        <FormLabel
          fontWeight="bold"
          htmlFor="toggle-high-contrast"
          mb={3}
          fontSize={fixedCustomStyles.textStyles.body.fontSize}
          lineHeight={fixedCustomStyles.textStyles.body.lineHeight}
        >
          High Contrast
        </FormLabel>

        <FormControl display="flex" alignItems="center">
          <Switch
            id="toggle-high-contrast"
            aria-label="toggle high contrast"
            mr={3}
            onChange={modeProps.toggleColorMode}
            isChecked={modeProps.colorMode === 'dark'}
            colorScheme="cyan"
            mb="0"
            fontSize={fixedCustomStyles.textStyles.body.fontSize}
            lineHeight={fixedCustomStyles.textStyles.body.lineHeight}
          >
            {mode('Off', 'On')(modeProps)}
          </Switch>
        </FormControl>
      </Box>
    </Fragment>
  );
};
