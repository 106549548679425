import axios, { AxiosResponse } from 'axios';

import { Election, ErrorResponse, PollingLocation } from '../types/googleCivic';
import { parseQueryParams } from '../utils/helpers';

import {
  getFakeGoogleCivicElections,
  getFakeGoogleCivicVoterInfo,
} from './_fakeApi';

const USING_FAKE_DATA = process.env.REACT_APP_TEST_DATA === 'true';
const instance = axios.create({
  baseURL: process.env.REACT_APP_CIVIC_BASE_URL,
});

const isError = <T>(
  res: AxiosResponse<T> | AxiosResponse<ErrorResponse>,
): res is AxiosResponse<ErrorResponse> =>
  (res as AxiosResponse<ErrorResponse>).data.error !== undefined;

/**
 * Gets all of the elections in the Google Civic API
 *
 * @returns the elections in the API
 */
const getElections = async (): Promise<Election[]> => {
  const params = {
    key: process.env.REACT_APP_CIVIC_SECRET,
  };

  if (USING_FAKE_DATA) {
    const res = await getFakeGoogleCivicElections();
    return res.data;
  }

  const uri = `/elections?${parseQueryParams(params)}`;
  const res = await instance.get(uri);
  return res.data.elections;
};

/**
 * Gets the voter info for an address and election.
 *
 * @param address the address to lookup
 * @param electionId the election id to lookup
 * @returns the voter info for the address and election
 */
const getVoterInfo = async (
  electionId: string,
  address?: string,
  isEarly?: boolean,
): Promise<PollingLocation[]> => {
  const params = {
    address: address,
    electionId: electionId,
    key: process.env.REACT_APP_CIVIC_SECRET,
  };

  if (USING_FAKE_DATA) {
    const res = await getFakeGoogleCivicVoterInfo();
    return res.data;
  }

  const uri = `/voterinfo?${parseQueryParams(params)}`;
  const res = await instance.get(uri);
  const pollingLocations = isEarly
    ? res.data.earlyVoteSites
    : res.data.pollingLocations;
  return pollingLocations;
};

export const GoogleCivicAPI = {
  getElections,
  getVoterInfo,
  isError,
};
