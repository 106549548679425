/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull brink-web-app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 9
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/implementation/brink-web-app)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'development' | 'production';

export const ApiKey: Record<Environment, string> = {
  development: '',
  production: process.env.REACT_APP_AMPLITUDE_KEY,
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '9',
    branch: 'main',
    source: 'brink-web-app',
    versionId: '908536a0-c7ca-41dd-98c4-4f6ac672ee2b',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export interface LoadOptions {
  environment?: Environment;
  disabled?: boolean;
  client?: {
    apiKey?: string;
    configuration?: BrowserOptions;
    instance?: BrowserClient;
  };
}

export interface IdentifyProperties {
  initial_dclid?: {
    [k: string]: any;
  };
  initial_fbclid?: {
    [k: string]: any;
  };
  initial_gclid?: {
    [k: string]: any;
  };
  initial_ko_click_id?: {
    [k: string]: any;
  };
  initial_msclkid?: {
    [k: string]: any;
  };
  initial_referrer?: {
    [k: string]: any;
  };
  initial_referring_domain?: {
    [k: string]: any;
  };
  initial_ttclid?: {
    [k: string]: any;
  };
  initial_twclid?: {
    [k: string]: any;
  };
  initial_utm_campaign?: {
    [k: string]: any;
  };
  initial_utm_content?: {
    [k: string]: any;
  };
  initial_utm_medium?: {
    [k: string]: any;
  };
  initial_utm_source?: {
    [k: string]: any;
  };
  initial_utm_term?: {
    [k: string]: any;
  };
}

export interface AboutBrinkLinkClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  aboutTitle: string;
}

export interface ChecklistLinkClickedProperties {
  isExternal: boolean;
  linkTitle: string;
  pageUrl: string;
}

export interface NearbyHelpLinkClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  helpLinkType: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  helpTitle: string;
}

export interface PageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pageLocation: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pagePath: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pageTitle: string;
}

export interface ResolvePollingPlacesClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  resolveTitle: string;
}

export interface SocialLinkClickedProperties {
  linkType: string;
  linkUrl: string;
}

export interface VoterFaqClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  faqTitle: string;
}

export class Identify implements BaseEvent {
  event_type = 'Identify';

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class Group implements BaseEvent {
  event_type = 'Group';
}

export class AboutBrinkLinkClicked implements BaseEvent {
  event_type = 'About Brink Link Clicked';

  constructor(public event_properties: AboutBrinkLinkClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChecklistLinkClicked implements BaseEvent {
  event_type = 'Checklist Link Clicked';

  constructor(public event_properties: ChecklistLinkClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class NearbyHelpLinkClicked implements BaseEvent {
  event_type = 'Nearby Help Link Clicked';

  constructor(public event_properties: NearbyHelpLinkClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PageViewed implements BaseEvent {
  event_type = 'Page Viewed';

  constructor(public event_properties: PageViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class ResolvePollingPlacesClicked implements BaseEvent {
  event_type = 'Resolve Polling Places Clicked';

  constructor(public event_properties: ResolvePollingPlacesClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SocialLinkClicked implements BaseEvent {
  event_type = 'Social Link Clicked';

  constructor(public event_properties: SocialLinkClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class VoterFaqClicked implements BaseEvent {
  event_type = 'Voter FAQ Clicked';

  constructor(public event_properties: VoterFaqClickedProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options?: LoadOptions): PromiseResult<void> {
    this.disabled = options?.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    const env = options?.environment ?? 'development';
    const apiKey = options?.client?.apiKey ?? ApiKey[env];

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Set Group for the current user
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  setGroup(groupType: string, groupName: string | string[], options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.setGroup(groupType, groupName, options);
  }

  /**
   * Identify a group and set group properties.
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  groupIdentify(
    groupType: string,
    groupName: string | string[],
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * About Brink Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest/About%20Brink%20Link%20Clicked)
   *
   * Owner: Khush Makadia
   *
   * @param properties The event's properties (e.g. aboutTitle)
   * @param options Amplitude event options.
   */
  aboutBrinkLinkClicked(
    properties: AboutBrinkLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AboutBrinkLinkClicked(properties), options);
  }

  /**
   * Checklist Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest/Checklist%20Link%20Clicked)
   *
   * Owner: Khush Makadia
   *
   * @param properties The event's properties (e.g. isExternal)
   * @param options Amplitude event options.
   */
  checklistLinkClicked(
    properties: ChecklistLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecklistLinkClicked(properties), options);
  }

  /**
   * Nearby Help Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest/Nearby%20Help%20Link%20Clicked)
   *
   * Owner: Khush Makadia
   *
   * @param properties The event's properties (e.g. helpLinkType)
   * @param options Amplitude event options.
   */
  nearbyHelpLinkClicked(
    properties: NearbyHelpLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NearbyHelpLinkClicked(properties), options);
  }

  /**
   * Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest/Page%20Viewed)
   *
   * Owner: Khush Makadia
   *
   * @param properties The event's properties (e.g. pageLocation)
   * @param options Amplitude event options.
   */
  pageViewed(
    properties: PageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageViewed(properties), options);
  }

  /**
   * Resolve Polling Places Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest/Resolve%20Polling%20Places%20Clicked)
   *
   * Owner: Khush Makadia
   *
   * @param properties The event's properties (e.g. resolveTitle)
   * @param options Amplitude event options.
   */
  resolvePollingPlacesClicked(
    properties: ResolvePollingPlacesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResolvePollingPlacesClicked(properties), options);
  }

  /**
   * Social Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest/Social%20Link%20Clicked)
   *
   * Owner: Khush Makadia
   *
   * @param properties The event's properties (e.g. linkType)
   * @param options Amplitude event options.
   */
  socialLinkClicked(
    properties: SocialLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SocialLinkClicked(properties), options);
  }

  /**
   * Voter FAQ Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/brink-election-guide/Brink%20(Test)/events/main/latest/Voter%20FAQ%20Clicked)
   *
   * Owner: Khush Makadia
   *
   * @param properties The event's properties (e.g. faqTitle)
   * @param options Amplitude event options.
   */
  voterFaqClicked(
    properties: VoterFaqClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VoterFaqClicked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
