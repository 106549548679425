import React, { FC, useEffect, useState } from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Center,
  Checkbox,
  Link,
  Text,
  useColorMode,
  VStack,
} from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { CheckItem, CheckItemContent } from '../../constants';
import { ParsedAuthority } from '../../types/contentful';
import { Alert } from '../ui/Alert';
import { ampli } from '../../ampli';

type TodoRouteKey = Extract<
  CheckItem,
  | 'find-poll-loc'
  | 'rev-vot-rights'
  | 'check-id-reqs'
  | 'go-to-poll'
  | 'use-vot-script'
  | 'choose-poll-loc'
  | 'go-to-early-poll'
  | 'mail-ballot'
  | 'research-candidates'
>;

// TODO: Add a link to the relevant page
const TODO_ROUTES: Record<TodoRouteKey, string> = {
  'find-poll-loc': '/home/checklist/pollingPlaces',
  'rev-vot-rights': '/home/help/polling/0',
  'check-id-reqs': '/home/checklist/IDRequirementsPage',
  'go-to-poll': '/home/checklist/pollingPlaces',
  'go-to-early-poll': '/home/checklist/pollingPlaces',
  'use-vot-script': '/home/help/polling/0',
  'mail-ballot': '/home/checklist/mailInfo',
  'choose-poll-loc': '/home/checklist/pollingPlaces',
  'research-candidates': '/home/ballot',
};

/**
 * Parses through the check items and gives a corresponding link to redirect to in the checklist
 *
 * @param id the checklist key to find a link for
 * @param authority the authority for the user's address that contains links
 *
 * @returns the link and whether the link is external
 */
const getTodoMeta = (id: CheckItem, authority: ParsedAuthority | {}) => {
  let link;
  let isExternal = false;

  switch (id) {
    case 'check-reg-status':
    case 'ooc-check-reg-status':
      if (authority !== {}) {
        link = (authority as ParsedAuthority).voterRegistrationStatusUrl;
      }
      isExternal = true;
      break;
    case 'reg-to-vote':
    case 'ooc-reg-to-vote':
    case 'apply-mail-ballot':
      if (authority !== {}) {
        link = (authority as ParsedAuthority)['homepage-url'];
      }
      isExternal = true;
      break;
    case 'find-poll-loc':
    case 'go-to-poll':
    case 'choose-poll-loc':
    case 'go-to-early-poll':
      link = TODO_ROUTES[id];
      isExternal = false;
      break;
    case 'rev-vot-rights':
    case 'check-id-reqs':
    case 'use-vot-script':
    case 'mail-ballot':
    case 'research-candidates':
      link = TODO_ROUTES[id];
      isExternal = false;
      break;
    default:
      console.error('Unknown todo item:', id);
      break;
  }

  return { link, isExternal };
};

interface DispatchAction {
  type: 'add-check' | 'remove-check';
  id: CheckItem;
}

interface TodoItemProps extends BoxProps {
  itemId: CheckItem;
  item: CheckItemContent;
  authority: ParsedAuthority | {};
  disabledAlert?: boolean;
  defaultChecked?: boolean;
  dispatch?: (_action: DispatchAction) => void;
}

export const TodoItem: FC<TodoItemProps> = ({
  itemId,
  item,
  authority,
  disabledAlert = false,
  defaultChecked = false,
  dispatch,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const [isExternal, setIsExternal] = useState(false);
  const [buttonLink, setButtonLink] = useState('');

  const modeProps = useColorMode();

  const handleChange = () => {
    dispatch?.({
      type: isChecked ? 'remove-check' : 'add-check',
      id: itemId,
    });

    setIsChecked((checked) => !checked);
  };

  useEffect(() => {
    const meta = getTodoMeta(itemId, authority);

    setIsExternal(meta.isExternal);
    setButtonLink(meta.link ?? '');
  }, [item, authority, itemId]);

  const logLinkClick = (
    link: string,
    external: boolean,
    title: string | null,
  ) => {
    ampli.checklistLinkClicked({
      pageUrl: link,
      isExternal: external,
      linkTitle: title ?? '',
    });
  };

  return (
    <VStack
      alignItems="start"
      width="50%"
      minWidth={['300px', '400px']}
      {...rest}
    >
      <Checkbox
        size="lg"
        defaultChecked={isChecked}
        onChange={handleChange}
        spacing="15px"
        borderColor={mode('black', 'white')(modeProps)}
      >
        <Text textStyle="h4" as="h2" fontWeight="bold">
          {item.title}
        </Text>
      </Checkbox>
      <VStack alignItems="start" paddingLeft="37px" spacing="7px" width="100%">
        <Text>{item.content}</Text>
        {!disabledAlert && item.alert && (
          <Alert padding="15px" variant="todo-alert">
            {item.alert}
          </Alert>
        )}
        {item.buttonLabel && (
          <Link
            mt={2}
            href={buttonLink}
            isExternal={isExternal}
            onClick={() =>
              logLinkClick(buttonLink, isExternal, item.buttonLabel)
            }
          >
            <Center>
              {item.buttonLabel}
              {isExternal && <ExternalLinkIcon mx="5px" />}
            </Center>
          </Link>
        )}
      </VStack>
    </VStack>
  );
};
