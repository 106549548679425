import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';
import { Download } from '@mui/icons-material';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { Position } from '../../types/ballotReady';
import { useBallotData, useFavoritedCandidates } from '../../contexts';
import { useIsDesktop } from '../../hooks';
import { Page } from '../../layout';
import {
  BackButton,
  MobileCandidateCard,
  ElectionLabel,
  BallotPrint,
} from '../../components';
import { capitalize } from '../../utils/helpers';

import BallotContainer from './BallotContainerPage';

interface FavCandidateProps {
  electionId: number;
  position: Position;
  candidates: Position['candidates'];
}

const FavoriteCandidateList: FC<FavCandidateProps> = ({
  electionId,
  position,
  candidates,
}) => {
  const level = position.level;
  const race = position.name;

  const BASE = encodeURI(`${electionId}/${level}/${race}`);
  const href = (candidateId: number) => `${BASE}/candidate/${candidateId}`;

  if (candidates.length === 0) {
    return (
      <UnorderedList key={position.name} listStyleType="none" pl={0} ml={0}>
        <MobileCandidateCard key={position.name} href={BASE} empty />
      </UnorderedList>
    );
  }

  return (
    <UnorderedList key={position.name} listStyleType="none" pl={0} ml={0}>
      {candidates.map((candidate) => (
        <ListItem key={candidate.id} ml={0} pl={0}>
          <MobileCandidateCard
            key={candidate.id}
            candidate={{
              id: candidate.id,
              name: `${candidate.first_name} ${candidate.last_name}`,
              party: candidate.party_name,
              image: candidate.thumb_url || candidate?.photo_url,
            }}
            href={href(candidate.id)}
          />
        </ListItem>
      ))}
    </UnorderedList>
  );
};

interface DeepestElement {
  candidates: Position['candidates'];
  position: Position;
}

export type BallotInterface = Record<
  number,
  Record<Position['level'], Record<Position['name'], DeepestElement>>
>;

export const FavoritesPage: FC = () => {
  const [ballot, setBallot] = useState<BallotInterface>({});
  const { elections, positions } = useBallotData();
  const { favoriteCandidates } = useFavoritedCandidates();

  const isDesktop = useIsDesktop();

  useEffect(() => {
    const getRaceCandidates = (position: Position) => {
      const candidates = position.candidates.reduce((acc, candidate) => {
        // Find all of the candidates in favorite candidates that are in this position candidates
        const selectedCandidates = [] as Position['candidates'];
        favoriteCandidates.forEach((favorite) => {
          if (Number(favorite.id) === candidate.id) {
            selectedCandidates.push(candidate);
          }
        });

        return [...acc, ...selectedCandidates];
      }, [] as Position['candidates']);

      return candidates;
    };

    // Create map of position level to races to the positions
    const structurePositions = (positions: Position[]) => {
      type RaceToPositions = Record<string, Position[]>;
      const structure = {} as Record<string, RaceToPositions>;

      positions.forEach((position) => {
        if (!structure[position.level]) {
          structure[position.level] = {} as RaceToPositions;

          structure[position.level][position.name] = [];
        } else if (!structure[position.level][position.name]) {
          structure[position.level][position.name] = [];
        }

        structure[position.level][position.name].push(position);
      });

      return structure;
    };

    const buildBallot = async () => {
      const ballot = {} as BallotInterface;

      await elections.forEach((election) => {
        ballot[election.id] = {};

        const electionPositions = positions[election.id];

        const formattedPositions = structurePositions(electionPositions);

        Object.entries(formattedPositions).forEach(([level, positions]) => {
          ballot[election.id][level] = {};

          Object.entries(positions).forEach(([race, positions]) => {
            ballot[election.id][level][race] = {
              candidates: [],
              position: {} as Position,
            };

            positions.forEach((position) => {
              const candidates = [
                ...ballot[election.id][level][race].candidates,
                ...getRaceCandidates(position),
              ];

              ballot[election.id][level][race].candidates = candidates;
              ballot[election.id][level][race].position = position;
            });
          });
        });
      });

      setBallot(ballot);
    };

    buildBallot();
  }, [elections, positions, favoriteCandidates]);

  return (
    <Page
      mainProps={{ mt: '10px' }}
      px="1em"
      w="100%"
      spacing="0px"
      alignItems="center"
      justifyContent="center"
      title="My Ballot"
      ariaLabel="my-ballot"
    >
      <Flex maxW="100%" w={['100%', '100%', '98%']}>
        <Box flexGrow={1}>
          <BackButton />
        </Box>
        {Object.keys(ballot).length > 0 && (
          <PDFDownloadLink
            document={<BallotPrint ballot={ballot} elections={elections} />}
            fileName="FavoriteCandidates_Brink.pdf"
          >
            <Link as={Button} fontWeight="bold">
              <Download /> {isDesktop && 'Download Ballot as PDF'}
            </Link>
          </PDFDownloadLink>
        )}
      </Flex>
      <BallotContainer
        hideBreadcrumbBar
        isLoading={Object.entries(ballot).length === 0}
        width={['98%', '95%', '95%', '100%']}
      >
        <Text as="h1" textStyle="h1">
          Favorite Candidates
        </Text>
        <Text color="brand.grey">
          Your data is yours! All your favorite candidates are stored on your
          device and not on Brink’s servers.
        </Text>

        {Object.entries(ballot).map(([electionId, election]) => (
          <Box mt="25px" key={electionId}>
            <ElectionLabel electionId={Number(electionId)} />
            {Object.entries(election).map(([level, raceMap]) => (
              <Box m="25px 0px" key={level}>
                <Text as="h2" textStyle="h2">
                  {capitalize(level)}
                </Text>
                <Box>
                  {Object.entries(raceMap).map(([race, value]) => (
                    <Box mt={5} key={race}>
                      <Text as="h3" textStyle="h3">
                        {value.position.name}
                      </Text>
                      <FavoriteCandidateList
                        candidates={value.candidates}
                        electionId={Number(electionId)}
                        position={value.position}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </BallotContainer>
    </Page>
  );
};

export default FavoritesPage;
