import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertTitle,
} from '@chakra-ui/react';
import type {
  AlertProps as ChakraAlertProps,
  AlertDescriptionProps,
  AlertTitleProps,
} from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';

import { useTextSize } from '../../contexts';
import { scaleValues } from '../../utils/helpers';

interface AlertProps extends ChakraAlertProps {
  title?: string;
  body?: ReactElement;
  titleProps?: AlertTitleProps;
  descriptionProps?: AlertDescriptionProps;
  fontColor?: string;
}

export const Alert: FC<AlertProps> = ({
  children,
  title = '',
  body,
  titleProps,
  descriptionProps,
  ...rest
}): ReactElement => {
  const { textSize } = useTextSize();

  return (
    <ChakraAlert
      // h={scaleValues('110px', '100px', textSize)}
      lineHeight={scaleValues('2px', '3px', textSize)}
      {...rest}
    >
      <AlertTitle
        {...titleProps}
        lineHeight={scaleValues('22px', '24px', textSize)}
        marginBottom={'5px'}
      >
        {title || body}
      </AlertTitle>
      <AlertDescription
        {...descriptionProps}
        lineHeight={scaleValues('20px', '22px', textSize)}
      >
        {children}
      </AlertDescription>
    </ChakraAlert>
  );
};
