import { List } from '@chakra-ui/react';
import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BallotListWrapper from '../../components/ballot/BallotListWrapper';
import BallotTitle from '../../components/ballot/BallotTitle';
import { CandidateGrid } from '../../components/ballot/CandidateGrid';
import { useBallotData } from '../../contexts';
import { Candidate } from '../../types/ballotReady';
import { capitalize } from '../../utils/helpers';

import BallotContainer from './BallotContainerPage';

export const CandidatesPage = (): ReactElement => {
  const { pathname } = useLocation();
  const positionName = pathname.split('/').pop();
  const electionTypeIndex = 2;
  const pathArray = pathname.split('/');
  const electionType =
    pathArray.length >= electionTypeIndex
      ? pathArray[pathArray.length - electionTypeIndex]
      : 'Election';
  const parsedName = positionName && decodeURI(positionName).toLowerCase();

  const { positions, isLoading } = useBallotData();
  const electionId = Number(
    window.location.href.match(/\/ballot\/(\d+)\/.*/)?.[1],
  );

  useEffect(() => {
    document.title = positionName
      ? `${capitalize(decodeURI(positionName))} | Brink`
      : 'Brink';
  }, [positionName]);

  const correspondingCandidates = positions[electionId]?.find(
    (position) => position.name.toLowerCase() === parsedName,
  )?.candidates;
  const getCandidateMessage = (correspondingCandidates?: Candidate[]) => {
    if (correspondingCandidates && correspondingCandidates?.length > 0) {
      return 'Select a candidate to learn more and add a candidate to your favorites.';
    }
    return 'Candidates are not available for this race. Check back in 2 weeks before the election to research the list of candidates!';
  };

  return (
    <BallotContainer
      minW="80%"
      includeElectionLabel
      isLoading={isLoading}
      mb="50px"
    >
      <BallotTitle
        name={positionName ? capitalize(decodeURI(positionName)) : ''}
        subheading={getCandidateMessage(correspondingCandidates)}
        display="center"
      />
      {correspondingCandidates && correspondingCandidates?.length > 0 && (
        <List w="100%">
          <BallotListWrapper
            length={correspondingCandidates?.length}
            countDescriptor="candidates"
          >
            <CandidateGrid
              candidateBioPath={`home/ballot/${electionId}/${electionType
                .toString()
                .toLowerCase()}/${positionName}/candidate`}
              candidates={correspondingCandidates}
            />
          </BallotListWrapper>
        </List>
      )}
    </BallotContainer>
  );
};
export default CandidatesPage;
