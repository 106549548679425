import React, { FC, ReactElement } from 'react';
import { chakra, Image } from '@chakra-ui/react';
import type { ImageProps } from '@chakra-ui/react';

export const NavLogo: FC<ImageProps> = ({ ...rest }): ReactElement => (
  <chakra.a href="/landing" padding="0" backgroundColor="white">
    <Image
      alt="Redirects to landing page"
      height="30px"
      fit="scale-down"
      {...rest}
    />
  </chakra.a>
);
