import React, { useState, useEffect, ChangeEvent } from 'react';
import { Center, Flex, Text, VStack, Select } from '@chakra-ui/react';
import PlaceIcon from '@mui/icons-material/Place';
import pluralize from 'pluralize';

import { GoogleCivicAPI } from '../../api/googleCivic';
import { useSavedAddress } from '../../contexts';
import { Election, PollingLocation } from '../../types/googleCivic';
import PollingPlace from '../../components/pollingLocations/PollingPlace';
import { Disclaimer } from '../../components/ui/Disclaimer';
import { Page } from '../../layout';
import {
  formatPollingLocationTitle,
  formatPollingLocationAddress,
  findPollingLocationHours,
  formatPollingLocationDate,
} from '../../utils/helpers';

export const PollingPlaces = () => {
  const { address } = useSavedAddress();
  const [elections, setElections] = useState<Election[]>([]);
  const [pollingPlaces, setPollingPlaces] = useState<PollingLocation[]>([]);
  const [displayOpen, setDisplayOpen] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string>(
    formatPollingLocationDate(new Date()),
  );

  const numUpcomingDays = 6;

  useEffect(() => {
    const loadElectionData = async () => {
      try {
        const res = await GoogleCivicAPI.getElections();

        if (res) {
          setElections(res);
        }
      } catch (error) {
        console.log(error);
      }
    };

    loadElectionData();
  }, []);

  useEffect(() => {
    if (elections.length === 0) {
      return;
    }

    const dateSetup = new Date(elections[0].electionDay);
    dateSetup.setDate(dateSetup.getDate() + 1);

    const firstElection = { id: elections[0].id };
    const loadPollingPlaces = async () => {
      try {
        const isEarly = selectedDate !== formatPollingLocationDate(dateSetup);
        const res = await GoogleCivicAPI.getVoterInfo(
          firstElection.id,
          address?.fullAddress,
          isEarly,
        );
        if (res?.length) {
          setPollingPlaces(res);
        }
      } catch (error) {
        console.log(error);
      }
    };

    loadPollingPlaces();
  }, [elections, address, selectedDate]);

  const handleDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.target.value);
    setDisplayOpen(e.target.value === formatPollingLocationDate(new Date()));
  };

  const displayDates = () =>
    [...Array(numUpcomingDays).keys()].map((num: number) => {
      const today = new Date();
      today.setDate(today.getDate() + num);
      const display = formatPollingLocationDate(today);

      const dateSetup = new Date(elections[0].electionDay);
      dateSetup.setDate(dateSetup.getDate() + 1);
      if (today > (dateSetup ?? new Date())) {
        return;
      }

      return (
        <option key={num} value={display}>
          {display}
        </option>
      );
    });

  return (
    <Page title="Polling Locations" ariaLabel="Polling Locations page">
      <Center margin={'auto'}>
        <Flex marginRight="10px">
          <PlaceIcon sx={{ fontSize: 32 }} />
        </Flex>
        <Text textStyle={['h2', 'h1']}>Nearby Polling Locations</Text>
      </Center>
      <Select
        variant="filled"
        onChange={handleDropdownChange}
        defaultValue={'Select date to vote'}
      >
        <option disabled value={'Select date to vote'}>
          Select date to vote
        </option>
        {elections.length && displayDates()}
      </Select>
      <Flex
        minWidth="100%"
        margin-top="0px"
        flexDir="column"
        alignContent="flex-start"
        justify="flex-start"
      >
        <Text mt="4px" mb="15px" color="grey">
          {`${pluralize('polling places', pollingPlaces.length, true)}`}
        </Text>
        <VStack>
          {pollingPlaces.map((pollingPlace, i) => (
            <PollingPlace
              key={i}
              name={formatPollingLocationTitle(pollingPlace)}
              address={formatPollingLocationAddress(pollingPlace)}
              hours={findPollingLocationHours(
                pollingPlace.pollingHours,
                selectedDate,
              )}
              displayOpen={displayOpen}
              maplinkAria={'View this address on Google Maps'}
              mb={'10px'}
            />
          ))}
        </VStack>
      </Flex>

      <Disclaimer
        heading="Don't see your polling location?"
        description="We use Google Civic to retrieve your polling locations.
          If you notice missing polling locations, they are likely
          missing from the Google Civic data set. To find a polling
          location near you, please visit your Secretary of State or
          Board of Elections website."
      />
    </Page>
  );
};

export default PollingPlaces;
