import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useState,
  useEffect,
} from 'react';

import type { LocaleType } from '../types/contentful/locale';

export type SetLocaleType = React.Dispatch<React.SetStateAction<LocaleType>>;

interface LocaleInterface {
  locale: LocaleType;
  setLocale: SetLocaleType;
}

const LocaleContext = createContext<LocaleInterface | undefined>(undefined);

//@TODO: Put all localstorage keys in a localstorage constants file
export const LOCALE_KEY = 'locale';

const LocaleContextProvider: FC = ({ children }): ReactElement => {
  const [locale, setLocale] = useState<LocaleType>(
    (localStorage.getItem(LOCALE_KEY) as LocaleType) ?? ('en-US' as LocaleType),
  );

  useEffect(() => {
    locale && localStorage.setItem(LOCALE_KEY, locale);
  }, [locale]);
  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (!context) {
    throw new Error('useLocale must be used inside the LocaleContext provider');
  }

  return context;
};
