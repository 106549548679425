import { Tag, useColorMode } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { TagProps } from '@chakra-ui/react';
import React, { FC } from 'react';

const PartyColors: Record<string, string> = {
  Democratic: 'party.democratic',
  Republican: 'party.republican',
  Green: 'party.green',
  Libertarian: 'party.libertarian',
};

const PartyShortnames: Record<string, string> = {
  Democratic: 'Dem',
  Republican: 'GOP',
  Green: 'Green',
  Libertarian: 'LP',
};

interface Props extends TagProps {
  party: string;
  shortname?: boolean;
}

export const PartyTag: FC<Props> = ({ party, shortname, ...rest }) => {
  const modeProps = useColorMode();

  const tagColor = mode(PartyColors[party] || 'grey', 'white')(modeProps);

  const tagText = shortname ? (
    <abbr title={party} style={{ textDecoration: 'none' }}>
      {PartyShortnames[party]}
    </abbr>
  ) : (
    party
  );

  return (
    <Tag
      bg="transparent"
      border="solid 1px"
      color={tagColor}
      borderColor={tagColor}
      m={2}
      {...rest}
    >
      {tagText}
    </Tag>
  );
};
