import { VStack, Text } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';

import { Alert, TodoItem } from '../../components';
import { useChecklist, useLocale, useSavedAddress } from '../../contexts';
import { OUT_OF_CYCLE_TODOS, checkItems } from '../../constants/checkItems';
import { Contentful } from '../../api';
import { states } from '../../constants';
import type { ParsedAuthority } from '../../types/contentful';
import { Page } from '../../layout';

const NUM_TODOS = OUT_OF_CYCLE_TODOS.data.length;

export const DefaultChecklistPage = (): ReactElement => {
  const { storedItems, dispatch } = useChecklist();
  const [count, setCount] = useState(storedItems.length);
  const { address } = useSavedAddress();
  const [authority, setAuthority] = useState<{} | ParsedAuthority>({});
  const { locale } = useLocale();

  useEffect(() => {
    const getAuthority = async (ocdId: string) =>
      setAuthority(await Contentful.getElectionAuthority(ocdId, locale));

    if (address) {
      getAuthority(address.ocdId);
    }
  }, [address, locale]);

  useEffect(() => {
    const completed = OUT_OF_CYCLE_TODOS.data.filter((item) =>
      storedItems.includes(item),
    );

    setCount(completed.length);
  }, [storedItems]);

  return (
    <>
      <Page title="Checklist" ariaLabel="election checklist page">
        <VStack w="100%" spacing="10px">
          <Text as="h1" textStyle="h1" alignSelf="flex-start">
            Voting Checklist
          </Text>
          {address && (
            <Alert title={`No current elections in ${states[address.state]}`}>
              Here's what you can do for now
            </Alert>
          )}
          <Text color="brand.black" alignSelf="flex-start">
            {count} out of {NUM_TODOS} action items checked
          </Text>
        </VStack>
        <VStack w="100%">
          {OUT_OF_CYCLE_TODOS.data.map((todo) => (
            <TodoItem
              w="100%"
              itemId={todo}
              item={checkItems[locale][todo]}
              authority={authority}
              dispatch={dispatch}
              key={checkItems[locale][todo].title}
              defaultChecked={storedItems.includes(todo)}
              disabledAlert
            />
          ))}
        </VStack>
      </Page>
    </>
  );
};
export default DefaultChecklistPage;
