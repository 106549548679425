import React, { ReactElement, FC } from 'react';
import { mode } from '@chakra-ui/theme-tools';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import foundations from '../../theme/foundations';
import { customStyles, styles as originalStyle } from '../../theme/styles';
import { useTextSize } from '../../contexts';
import { scale } from '../../utils/helpers';
import components from '../../theme/components';

const TextSizeWrapper: FC = ({ children }): ReactElement => {
  const { textSize } = useTextSize();

  const scaledCustomStyles = JSON.parse(JSON.stringify(customStyles)); // deep copy of customStyles

  Object.keys(customStyles.textStyles).forEach((key) => {
    const { fontSize, lineHeight } = customStyles.textStyles[key];

    scaledCustomStyles.textStyles[key].fontSize = scale(fontSize, textSize);
    scaledCustomStyles.textStyles[key].lineHeight = scale(lineHeight, textSize);
  });

  const fontSizeArr: string[] = originalStyle.global({}).body.fontSize;
  const lineHeightArr: string[] = originalStyle.global({}).body.lineHeight;

  const styles = {
    global: (props: any) => ({
      body: {
        bg: mode('brand.white', 'brand.black')(props),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: scale(fontSizeArr, textSize),
        lineHeight: scale(lineHeightArr, textSize),
      },
    }),
  };

  const theme = extendTheme({
    ...foundations,
    styles,
    ...scaledCustomStyles,
    components,
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default TextSizeWrapper;
