import React from 'react';
import {
  Button,
  Flex,
  Icon,
  Link,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { Star } from '@mui/icons-material';

import { fixedCustomStyles } from '../../theme/foundations/fixedTextSizes';
import { useIsDesktop } from '../../hooks';
import { BackButton } from '../button/BackButton';

import { BallotBreadcrumbs } from './BallotBreadcrumbs';

interface BreadcrumbBarProps {
  showFavorites: boolean;
}

export const BreadcrumbBar = (props: BreadcrumbBarProps) => {
  const { showFavorites } = props;
  const { sizes } = useTheme();
  const isDesktop = useIsDesktop();

  const modeProps = useColorMode();
  return (
    <Flex
      backgroundColor={
        isDesktop ? mode('brand.lightGray', 'black')(modeProps) : 'transparent'
      }
      p={isDesktop ? '0 35px' : '0 15px'}
      width="100%"
      position={isDesktop ? 'sticky' : 'relative'}
      zIndex="docked"
      top={
        isDesktop ? `calc(${sizes.navHeight} + ${sizes.tabHeight})` : undefined
      }
      height={sizes.breadcrumbsHeight}
      fontSize={fixedCustomStyles.textStyles.h4.fontSize}
      borderBottom={isDesktop ? '1px solid grey' : 'none'}
      justifyContent="space-between"
      alignItems="center"
    >
      {!isDesktop ? (
        <Flex>
          <BackButton text="Back" />
        </Flex>
      ) : (
        <BallotBreadcrumbs />
      )}
      {showFavorites && (
        <Button
          as={Link}
          backgroundColor={mode('white', 'black')(modeProps)}
          leftIcon={<Icon as={Star} color="inherit" />}
          href="/home/ballot/favorites"
          variant="alt"
        >
          Favorite Candidates
        </Button>
      )}
    </Flex>
  );
};
