import React, { ReactElement } from 'react';
import {
  chakra,
  UnorderedList,
  ListItem,
  Icon,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const DesktopFooter = (): ReactElement => {
  const modeProps = useColorMode();

  const linkItems = [
    {
      href: 'https://www.brinkapp.co',
      title: 'Download Mobile App',
    },
    {
      href: 'https://www.facebook.com/brinkelectionguide',
      title: 'Facebook',
    },
    {
      href: 'https://twitter.com/BrinkVoting',
      title: 'Twitter',
    },
    {
      href: 'https://www.youtube.com/channel/UC1vQ1pxG6RH_ook517newHQ',
      title: 'YouTube',
    },
  ];

  return (
    <chakra.footer
      top="10"
      zIndex="overlay"
      aria-label="footer"
      height="navHeight"
    >
      <UnorderedList
        marginLeft="0"
        listStyleType="none"
        backgroundColor={mode('brand.primary', 'black')(modeProps)}
        alignItems="center"
        height="navHeight"
        width="100%"
        justifyContent="space-evenly"
        display="flex"
        borderTop={'1px solid grey'}
      >
        {linkItems.map((item) => (
          <ListItem key={item.title}>
            <chakra.a color="white" href={item.href} target="_blank">
              <Text as="b">{item.title}</Text>
              <Icon
                as={ExternalLinkIcon}
                w={4}
                h={4}
                mb={1}
                color="white"
                marginLeft="10px"
              />
            </chakra.a>
          </ListItem>
        ))}
      </UnorderedList>
    </chakra.footer>
  );
};

export default DesktopFooter;
