import React, { useEffect, useMemo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Box, Spinner, Center } from '@chakra-ui/react';

import { useSavedAddress, useBallotData } from './contexts';
import {
  LandingPage,
  HelpPage,
  HomepageWrapper,
  CandidatesPage,
  PositionsPage,
  VoterFAQPage,
  RacesPage,
  PollingPlaceIssuePage,
  VotingMethodSelectionPage,
  VotingMethodInfoPage,
  FavoritesPage,
  ElectionChecklistPage,
  DefaultChecklistPage,
  CandidatePage,
  IDRequirementsPage,
  MailInVotingInfo,
  PollingPlaces,
} from './pages';
import { Navbar } from './layout';
import { SkipContentLink } from './components';
import { Footer } from './layout/Footer';
import { ampli } from './ampli';

export const App = () => {
  const { elections, isLoading } = useBallotData();
  const { pathname } = useLocation();

  useEffect(() => {
    ampli.load({ environment: 'production' });
  }, []);

  useEffect(() => {
    ampli.pageViewed({
      pageLocation: window.location.href,
      pagePath: pathname,
      pageTitle: document.title ?? 'No title',
    });
  }, [pathname]);
  const { address } = useSavedAddress();

  const showFooter = useMemo(() => {
    // TODO: Finalize which pages should not show the footer
    const pagesToNotShow = ['/landing', '/polling'];

    return !pagesToNotShow.some((page) => pathname.includes(page));
  }, [pathname]);

  const getChecklistPage = () => {
    if (!address) {
      return <Navigate to={'/landing'} />;
    }

    if (isLoading) {
      return (
        <Center height="90vh">
          <Spinner size="xl" />
        </Center>
      );
    }

    return elections.length > 0 ? (
      <ElectionChecklistPage />
    ) : (
      <DefaultChecklistPage />
    );
  };

  function Donate() {
    window.location.href = 'https://www.brinkapp.co/donate';
    return null;
  }

  return (
    <Box>
      <SkipContentLink text="Skip Main Navigation" href="#skip-nav" />
      <Navbar />
      <Routes>
        <Route path={'/landing'} element={<LandingPage />} />
        <Route
          path={'/home/help/polling/:id'}
          element={<PollingPlaceIssuePage />}
        />
        <Route
          path={'/home/help'}
          element={<HomepageWrapper page={<HelpPage />} />}
        />
        <Route path={'/donate'} element={<Donate />} />
        <Route
          path={'/home/select'}
          element={<HomepageWrapper page={<VotingMethodSelectionPage />} />}
        />
        <Route
          path={'/home/checklist'}
          element={<HomepageWrapper page={getChecklistPage()} />}
        />
        <Route
          path={'/home/checklist/IdRequirementsPage'}
          element={<IDRequirementsPage />}
        />
        <Route
          path={'/home/faq'}
          element={<HomepageWrapper page={<VoterFAQPage />} />}
        />
        <Route
          path={
            '/home/ballot/:electionId/:level/:electionName/candidate/:candidateId'
          }
          element={<HomepageWrapper page={<CandidatePage />} />}
        />
        <Route
          path={'/home/ballot'}
          element={<HomepageWrapper page={<RacesPage />} />}
        />
        <Route
          path={'/home/ballot/favorites'}
          element={<HomepageWrapper page={<FavoritesPage />} />}
        />
        <Route
          path={'/home/ballot/:electionId/:level'}
          element={<HomepageWrapper page={<PositionsPage />} />}
        />
        <Route
          path={'/home/ballot/:electionId/:level/:electionName'}
          element={<HomepageWrapper page={<CandidatesPage />} />}
        />
        <Route
          path={'/home/select/:votingMethod'}
          element={<HomepageWrapper page={<VotingMethodInfoPage />} />}
        />
        <Route
          path={'/home/checklist/mailInfo'}
          element={<MailInVotingInfo />}
        />
        <Route
          path={'/home/checklist/pollingPlaces'}
          element={<HomepageWrapper page={<PollingPlaces />} />}
        />
        <Route path="*" element={<Navigate to={'/landing'} />} />
      </Routes>

      {showFooter && <Footer />}
    </Box>
  );
};
export default App;
