import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import ProviderWrapper from './contexts/AppContext';
import TextSizeWrapper from './components/textSizeWrapper/TextSizeWrapper';
import { sendWebVitals } from './utils/analytics';
import './styles/styles.scss';
import App from './app';

ReactDOM.render(
  <React.StrictMode>
    <ProviderWrapper>
      <TextSizeWrapper>
        <Router>
          <App />
        </Router>
      </TextSizeWrapper>
    </ProviderWrapper>
  </React.StrictMode>,

  document.getElementById('root'),
);

sendWebVitals({
  analyticsId: process.env.REACT_APP_VERCEL_ANALYTICS_ID,
  debug: false, // TODO: have this set by a debug ENV variable
  path: window.location.pathname,
});
