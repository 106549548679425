import React, { FC, ReactElement } from 'react';

import FavoritesContextProvider from './FavCandidatesContext';
import AddressProvider from './AddressContext';
import ChecklistContextProvider from './ChecklistContext';
import VotingMethodContextProvider from './VotingMethodContext';
import TextSizeContextProvider from './TextSizeContext';
import { BallotDataProvider } from './BallotContext';
import LocaleContextProvider from './LocaleContext';

const ProviderWrapper: FC = ({ children }): ReactElement => (
  <TextSizeContextProvider>
    <FavoritesContextProvider>
      <AddressProvider>
        <ChecklistContextProvider>
          <VotingMethodContextProvider>
            <LocaleContextProvider>
              <BallotDataProvider>{children}</BallotDataProvider>
            </LocaleContextProvider>
          </VotingMethodContextProvider>
        </ChecklistContextProvider>
      </AddressProvider>
    </FavoritesContextProvider>
  </TextSizeContextProvider>
);

export default ProviderWrapper;
