import { Text, VStack } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';

import { ampli } from '../ampli';
import { Contentful } from '../api';
import { FAQAccordion } from '../components';
import { Disclaimer } from '../components/ui/Disclaimer';
import { Page } from '../layout';
import { HelpContent } from '../types/contentful';
import { useLocale } from '../contexts';

export const VoterFAQPage = (): ReactElement => {
  const [helpContents, setHelpContents] = useState<HelpContent[]>([]);
  const { locale } = useLocale();

  useEffect(() => {
    document.title = 'FAQ | Brink';
  }, []);

  useEffect(() => {
    // TODO: How do we indicate loading states for the API in a way that is accessible?
    const loadHelpContent = async (): Promise<void> => {
      const res = await Contentful.getHelpContent('faq', locale);
      // TODO: Should it throw an error if it doesn't work when trying to get FAQ information?
      setHelpContents(res);
    };
    loadHelpContent();
  }, [locale]);

  return (
    <>
      <Page
        title="FAQ"
        ariaLabel="frequently asked questions page"
        marginBottom="20px"
      >
        <Text as="h1" textStyle="h1">
          Frequently Asked Questions
        </Text>
        <VStack spacing="13px">
          {helpContents.map((helpContent) => (
            <FAQAccordion
              headerText={helpContent.title}
              panelText={helpContent.body}
              key={helpContent.title}
              buttonProps={{ textStyle: 'h4' }}
              onClick={() => {
                ampli.voterFaqClicked({
                  faqTitle: helpContent.title,
                });
              }}
            />
          ))}
        </VStack>
        <Disclaimer
          heading="Don't see your question or issue?"
          description="Check out the Help page to get nearby help or resolve polling place issues!"
          hyperlink="/home/help"
          hyperlinkText="Go To Help"
        />
      </Page>
    </>
  );
};
export default VoterFAQPage;
