import { Address } from '../types';
import { PollingLocation } from '../types/googleCivic/pollingData';

export const getLocalityName = (address: Address): string | null => {
  const { locality, sublocality, sublocality_level_1 } = address;

  if (locality) {
    return locality.long_name;
  } else if (sublocality) {
    return sublocality.long_name;
  } else if (sublocality_level_1) {
    return sublocality_level_1.long_name;
  }

  return null;
};

export const getAddressQuery = (address: Address): string => {
  const { street_number, route, postal_code, administrative_area_level_1 } =
    address;
  const localityName = getLocalityName(address);

  const street = `${street_number && street_number.short_name} ${
    route && route.short_name
  }`;
  const cityStateZip = `${localityName && localityName}, ${
    administrative_area_level_1 && administrative_area_level_1.short_name
  } ${postal_code && postal_code.long_name}`;

  return `${street}, ${cityStateZip}`;
};

export const parseQueryParams = (
  params: Record<string | number, unknown>,
): string =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const isNumber = (value: any): value is number => {
  if (typeof value === 'string' && !Number.isNaN(Number(value))) {
    return true;
  }

  return false;
};

export const formatDate = (date: string) => {
  const d = new Date(date);

  const isoString = d.toISOString();

  const year = Number(isoString.substring(0, 4));
  const month = Number(isoString.substring(5, 7));
  const day = Number(isoString.substring(8, 10));

  const fullMonth = [
    'January',
    'Febuary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][month - 1];

  return `${fullMonth} ${day}, ${year}`;
};

export const formatDay = (day: number): string => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][day];

  return days;
};

const getPollingLocationMonth = (monthNum: number): string => {
  const mon = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ][monthNum];

  return mon;
};

export const formatPollingLocationTitle = (pollingPlace: PollingLocation) =>
  `${pollingPlace.address.locationName}`;

export const formatPollingLocationAddress = (pollingPlace: PollingLocation) => {
  const address_data = pollingPlace.address;
  return `${address_data.line1}, ${address_data.city}, ${address_data.state}, ${address_data.zip}`;
};

const getPollingLocationDay = (dayOfWeekNum: number): string => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeekNum];

  return days;
};

export const createGoogleMapsUrl = (address: string) =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address,
  )}`;

export const formatTimeAsMins = (time: string) => {
  const splitTime = time.trim().split(' ');

  if (splitTime[1].trim() === 'am') {
    if (splitTime[0].trim().split(':').length === 1) {
      return parseInt(splitTime[0].trim()) * 60;
    }
    return (
      parseInt(splitTime[0].trim().split(':')[0]) * 60 +
      parseInt(splitTime[0].trim().split(':')[1])
    );
  }

  const additive = 12;
  if (splitTime[0].trim().split(':').length === 1) {
    return (parseInt(splitTime[0].trim()) + additive) * 60;
  }
  return (
    (parseInt(splitTime[0].trim().split(':')[0]) + additive) * 60 +
    parseInt(splitTime[0].trim().split(':')[1])
  );
};

export const findPollingLocationHours = (
  hours: string,
  selectedDate: string,
) => {
  const substrHrs = `${hours}\n`.substring(`${hours}\n`.indexOf(selectedDate));
  return substrHrs.substring(
    substrHrs.indexOf(':') + 2,
    substrHrs.indexOf('\n'),
  );
};

export const formatPollingLocationDate = (date: Date) =>
  `${getPollingLocationDay(date.getDay())}, ${getPollingLocationMonth(
    date.getMonth(),
  )} ${date.getDate()}`;

export const capitalize = (str: string): string =>
  str
    .split(' ')
    .map((word) => {
      if (word.indexOf('.') > 0) {
        return word.toUpperCase();
      } else if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(' ');

// scaleHelper strips 'px' and multiplies fontSize by scalar
export const scaleHelper = (fontSize: string, scaler: number): string => {
  // strip 'px' from the string
  const size = fontSize.substring(0, fontSize.length - 2);

  const sizeValue = parseInt(size);
  if (isNaN(sizeValue)) {
    // something went wrong, then just return original textSize
    console.log('no size provided by slider');
    return fontSize;
  }

  const scaledValue = scaler * sizeValue;
  return `${scaledValue}px`;
};

export const scaleValues = (
  originalMobile: string,
  originalWeb: string,
  textSize: number,
): string[] => {
  const scaledMobile = scaleHelper(originalMobile, textSize);
  const scaledWeb = scaleHelper(originalWeb, textSize);

  return [scaledMobile, scaledWeb];
};

// scale text sizes based on accessibility slider value
export const scale = (fontSizeTag: string[], textSize: number): string[] => {
  if (fontSizeTag.length < 2) {
    throw new Error('fontSizeTag should have 2 elements');
  }
  const originalStringMobile = fontSizeTag[0];
  const originalStringWeb = fontSizeTag[1];
  return scaleValues(originalStringMobile, originalStringWeb, textSize);
};
