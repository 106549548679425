import React, { FC, ReactElement } from 'react';
import { VStack, Text, Link } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface DisclaimerProps {
  heading: string;
  description: string;
  hyperlink?: string;
  hyperlinkText?: string;
}

export const Disclaimer: FC<DisclaimerProps> = ({
  heading,
  description,
  hyperlink,
  hyperlinkText,
}): ReactElement => (
  <VStack width="100%" alignItems="start" spacing="20px">
    <Text as="h2" textStyle="h2">
      {heading}
    </Text>
    <Text>
      {description}
      {hyperlink && (
        <Link href={hyperlink} variant="inline" ml="5px">
          {hyperlinkText}
          {/* Hiding the icon because it reads "Image" */}
          <ArrowForwardIcon ml={1} mb="0.1em" aria-hidden="true" />
        </Link>
      )}
    </Text>
  </VStack>
);
