interface FontStyle {
  fontStyle: string;
  fontWeight: number;
  fontSize: [string, string]; //string[];
  lineHeight: string[];
}

export const fixedCustomStyles: Record<string, Record<string, FontStyle>> = {
  textStyles: {
    h1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: ['28px', '32px'],
      lineHeight: ['41px', '47px'],
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: ['22px', '28px'],
      lineHeight: ['32px', '41px'],
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: ['18px', '22px'],
      lineHeight: ['26px', '32px'],
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: ['16px', '18px'],
      lineHeight: ['24px', '26px'],
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: ['14px', '16px'],
      lineHeight: ['20px', '24px'],
    },
    body: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: ['14px', '16px'],
      lineHeight: ['20px', '22px'],
    },
    sliderDefault: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: ['16px', '16px'],
      lineHeight: ['20px', '22px'],
    },
    sliderLarge: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: ['22px', '24px'],
      lineHeight: ['32px', '41px'],
    },
    homePageTab: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: ['16px', '18px'],
      lineHeight: ['22px', '24px'],
    },
  },
};
