import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  VStack,
  UnorderedList,
  ListItem,
  Center,
  Spinner,
} from '@chakra-ui/react';

import { EndorsementsAccordion } from '../../components/accordion/EndorsementsAccordion';
import { BallotReadyAPI } from '../../api';
import type {
  Candidate,
  FullCandidate,
  Endorsement,
} from '../../types/ballotReady';
import { FavoriteButton, PartyTag, SocialLink } from '../../components';
import { useBallotData, useFavoritedCandidates } from '../../contexts';
import { useIsMobile, useIsTablet } from '../../hooks';
import emptyProfilePic from '../../assets/pfp.png';
import { ampli } from '../../ampli';

import { BallotContainerPage } from './BallotContainerPage';

export const CandidatePage = (): ReactElement => {
  const candidateId = window.location.href.split('/').pop();
  const electionId = Number(
    window.location.href.match(/\/ballot\/(\d+)\/.*/)?.[1],
  );

  const { favoriteCandidates } = useFavoritedCandidates();
  const [fullCandidate, setFullCandidate] = useState<FullCandidate>();
  const [candidate, setCandidate] = useState<Candidate>();
  const { positions, isLoading } = useBallotData();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const allCandidates = positions[electionId]?.reduce<Candidate[]>(
    (acc, position) => [...acc, ...position.candidates],
    [],
  );

  const selectedCandidate = allCandidates?.find(
    (candidate) => String(candidate.id) === candidateId,
  );

  const getEndorsementBuckets = (
    endorsements: Endorsement[],
  ): Record<string, Endorsement[]> => {
    let endorsementNames: string[] = new Array(); // allows us to keep track of endorsement names to prevent duplications
    const endorsementBuckets = endorsements.reduce((buckets, endorsement) => {
      // buckets candidate's endorsements into issues
      const issueName = endorsement.issue_name;
      if (issueName !== null && endorsement !== null) {
        buckets[issueName] = buckets[issueName] || [];
        if (!endorsementNames.includes(endorsement.name)) {
          buckets[issueName].push(endorsement);
          endorsementNames.push(endorsement.name);
        }
      }
      return buckets;
    }, Object.create({}));
    return endorsementBuckets;
  };

  useEffect(() => {
    const loadFullCandidate = async (candidateId: string) => {
      const res = await BallotReadyAPI.getCandidateById(Number(candidateId));

      if (!BallotReadyAPI.isError(res)) {
        setFullCandidate(res.data);
      }
    };

    if (candidateId) {
      loadFullCandidate(candidateId);
      setCandidate(selectedCandidate);
    }

    if (candidate) {
      document.title = `${candidate.first_name} ${candidate.last_name} | Brink`;
    }
  }, [candidate, selectedCandidate, candidateId]);

  return isLoading ? (
    <Center height="80vh">
      <Spinner size="lg"></Spinner>
    </Center>
  ) : (
    <Box>
      {candidate && (
        <BallotContainerPage
          minHeight={`calc(100vh - 60px)`}
          includeElectionLabel
          electionId={electionId}
          marginTop="0px"
        >
          <Flex
            direction="column"
            justify="space-between"
            align="center"
            maxW="550px"
            paddingTop="0px"
            paddingBottom="25px"
            alignItems="center"
            alignSelf="center"
            justifyContent="center"
            marginLeft="auto"
            marginRight="auto"
          >
            <FavoriteButton
              alignSelf="flex-end"
              m={0}
              aria-label="Add candidate to favorites"
              candidate={candidate}
              defaultChecked={favoriteCandidates.some(
                (el) =>
                  el.name === `${candidate.first_name} ${candidate.last_name}`,
              )}
            />
            <Image
              alt=""
              src={candidate.photo_url || emptyProfilePic}
              borderRadius="50%"
              w="185px"
              h="185px"
              bgColor="base"
              margin="10px"
              objectFit="cover"
            />
            <Flex direction="column" align="center" justify="space-between">
              <Text
                as="h1"
                textStyle={'h1'}
              >{`${candidate.first_name} ${candidate.last_name}`}</Text>
              <PartyTag party={candidate.party_name} />
            </Flex>
            <Flex
              direction="row"
              justifyContent={
                candidate.urls.length === 3 && !(isMobile || isTablet)
                  ? 'space-between'
                  : 'space-evenly'
              }
              width="100%"
              maxWidth="550px"
              marginTop="10px"
              marginBottom="10px"
              marginLeft="auto"
              marginRight="auto"
            >
              {candidate.urls.map((url, index) => (
                <SocialLink
                  onClick={() => {
                    ampli.socialLinkClicked({
                      linkType: url.type,
                      linkUrl: url.url,
                    });
                  }}
                  candidateUrl={url}
                  key={index}
                />
              ))}
            </Flex>
          </Flex>
          <VStack
            width="100%"
            maxWidth="600px"
            minWidth="300px"
            alignItems="flex-start"
            marginTop="10px"
            marginBottom="25px"
            marginLeft="auto"
            marginRight="auto"
          >
            {fullCandidate?.education.length && (
              <Text
                as="h4"
                textStyle="h4"
                alignSelf="flex-start"
                borderLeft="4px"
                borderColor="blue"
                paddingLeft="6px"
              >
                Education
              </Text>
            )}
            <UnorderedList paddingLeft="30px">
              {fullCandidate?.education.map(
                (education) =>
                  education.school && (
                    <ListItem key={education.education_id}>
                      {[
                        education.school,
                        education.degree,
                        education.major,
                        education.grad_year,
                      ]
                        .filter((_) => _)
                        .join(', ')}
                    </ListItem>
                  ),
              )}
            </UnorderedList>
            {fullCandidate?.experience.length && (
              <Text
                as="h4"
                textStyle="h4"
                alignSelf="flex-start"
                borderLeft="4px"
                borderColor="blue"
                paddingLeft="6px"
              >
                Experience
              </Text>
            )}
            <UnorderedList paddingLeft="30px">
              {fullCandidate?.experience.map((experience, index) => (
                <ListItem key={index}>
                  {[
                    experience.position,
                    experience.company,
                    experience.start_year,
                    experience.end_year,
                  ]
                    .filter((_) => _)
                    .join(', ')}
                </ListItem>
              ))}
            </UnorderedList>

            {fullCandidate?.endorsements?.length && (
              <Text
                as="h4"
                textStyle="h4"
                alignSelf="flex-start"
                borderLeft="4px"
                borderColor="blue"
                paddingLeft="6px"
              >
                Endorsements
              </Text>
            )}

            <VStack spacing="13px" mb="20px" width="100%">
              {Object.keys(
                getEndorsementBuckets(fullCandidate?.endorsements ?? []),
              ).map((issue_name) => (
                <EndorsementsAccordion
                  headerText={issue_name}
                  endorsements={
                    getEndorsementBuckets(fullCandidate?.endorsements!)[
                      issue_name
                    ]
                  }
                  buttonProps={{ textStyle: 'h4' }}
                  key={issue_name}
                />
              ))}
            </VStack>
          </VStack>
        </BallotContainerPage>
      )}
    </Box>
  );
};
