import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import {
  chakra,
  UnorderedList,
  Icon,
  Text,
  useColorMode,
  Link,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { Edit, LocationOn as LocationOnIcon } from '@mui/icons-material';

import { fixedCustomStyles } from '../theme/foundations/fixedTextSizes';
import {
  NavDesktopLinks,
  NavItem,
  NavLogo,
  NavTabletAndMobileMenu,
} from '../components';
import { useSavedAddress } from '../contexts';
import { useIsDesktop } from '../hooks';
import HalfLogo from '../assets/logo/logo.png';
import FullLogo from '../assets/logo/logo-mark.png';
import FullContrastLogo from '../assets/logo/reverse-logo-mark.png';
import HalfContrastLogo from '../assets/logo/reverse-logo.png';

export const Navbar = (): ReactElement => {
  const { address } = useSavedAddress();
  const { pathname } = useLocation();
  const isDesktop = useIsDesktop();

  const modeProps = useColorMode();

  const shouldShowAddress = () => address && pathname !== '/landing';
  const shouldShowFullLogo = () => isDesktop || pathname === '/landing';
  const shouldShowBottomBar = () =>
    (pathname !== '/landing' && !pathname.startsWith('/home')) ||
    (pathname.includes('polling') && !pathname.includes('pollingPlaces'));

  const getLogo = () => {
    if (modeProps.colorMode === 'dark') {
      return shouldShowFullLogo() ? FullContrastLogo : HalfContrastLogo;
    }

    return shouldShowFullLogo() ? FullLogo : HalfLogo;
  };

  return (
    <chakra.nav
      position="sticky"
      top="0"
      zIndex="overlay"
      aria-label="primary navigation"
      height="navHeight"
    >
      <UnorderedList
        marginLeft="0"
        listStyleType="none"
        backgroundColor={mode('brand.lightBase', 'black')(modeProps)}
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        height="navHeight"
        display="flex"
        borderBottom={shouldShowBottomBar() ? '1px solid grey' : '0px'}
      >
        <NavItem marginLeft="25px">
          <NavLogo src={getLogo()} />
        </NavItem>
        {shouldShowAddress() && (
          <Link
            variant="darkInline"
            href="/landing"
            display="flex"
            textStyle="h4"
            fontSize={fixedCustomStyles.textStyles.h4.fontSize}
            lineHeight={fixedCustomStyles.textStyles.h4.lineHeight}
            maxWidth="60%"
          >
            <Icon as={LocationOnIcon} color="inherit" />
            <Text isTruncated>{address?.fullAddress}</Text>
            <Icon ml={2} as={Edit} color="inherit" />
          </Link>
        )}
        {isDesktop ? <NavDesktopLinks /> : <NavTabletAndMobileMenu />}
      </UnorderedList>
    </chakra.nav>
  );
};

export default Navbar;
